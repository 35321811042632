<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Docker</h4>
            <div class="small text-muted">Administración de sistemas con Docker</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Sistema">
              <i class="fa fa-plus"></i> Agregar
            </b-button>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">            
        <b-row>            
          <b-col sm="12">
            <b-row>                              
              <b-col sm="3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Buscar por nombre o dominio" v-model="filters.name" v-on:keyup.enter.native="filter"></b-form-input>              
                  </b-input-group>
                </b-form-group>                                        
              </b-col>
              <b-col sm="3">
                <v-select :options="arr.select.customers" v-model="filters.customer" placeholder="Cliente" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>              
              <b-col sm="3">
                <v-select :options="arr.select.instances_servers" v-model="filters.instances_server" placeholder="Servidor Instalación" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filter()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>  
            </b-row>     
          </b-col>  
        </b-row>         
      </b-card>

      <b-card header-tag="header" footer-tag="footer">  
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"     
                    v-if="table.items.length || table.isBusy">                       
                
                <template v-slot:cell(id)="data">
                  <b>#{{data.item.id}}</b>
                </template>
                
                <template v-slot:cell(name)="data">
                  <b>{{data.item.name}}</b>
                                    
                  <span>
                    <br>
                    <b-icon v-if="!data.item.status_disable" icon="circle-fill" variant="success" title="Habilitado"></b-icon> 
                    <b-icon v-else icon="circle-fill" variant="danger" title="Deshabilitado"></b-icon> 

                    <span v-if="data.item.domain">                    
                      <b-link target="_blank" :href="'http://'+data.item.domain">
                        https://{{data.item.domain}}
                      </b-link>                    
                    </span>
                    <span v-else class="text-danger">
                      [Falta configuración de dominio para el sistema]
                    </span>
                  </span>
                </template>

                <template v-slot:cell(customer_id)="data">
                  <div v-if="data.item.customer">            
                    <b-avatar :src="data.item.customer.image"
                              v-if="data.item.customer.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>                                                     
                    &nbsp;<b>{{data.item.customer.name}}</b>   
                    
                    <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                  </div>
                </template>
                
                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations">
                    <b-icon icon="chat-square-text"></b-icon>
                  </div>                           
                </template>

                <template v-slot:cell(instances_server_id)="data">
                  <span v-if="data.item.server">
                    <b>{{data.item.server.name}}</b><br>
                    <span v-if="data.item.server.subdomain">
                      <b-link target="_blank" :href="'http://'+data.item.server.subdomain">
                        https://{{data.item.server.subdomain}}
                      </b-link>  
                    </span>
                  </span>
                </template>                                  

                <template v-slot:cell(services)="data">                  
                  <div v-if="data.item.service_customer" v-b-tooltip.hover :title="data.item.service_customer.name" style="cursor:pointer;">
                    <i class="fa fa-rocket"></i>
                  </div>                           
                </template>  

                <template v-slot:cell(instances_server_backups_id)="data">
                  <span v-if="data.item.server_backups">
                    <b>{{data.item.server_backups.name}}</b>                    
                  </span>
                  &nbsp;&nbsp;
                  <span v-if="data.item.observations_backups" v-b-tooltip.hover :title="data.item.observations_backups" style="cursor:pointer;">
                    <b-icon icon="shield-fill-exclamation"></b-icon>
                  </span>                                      
                </template>

                <template v-slot:cell(backups)="data">
                  <div v-if="data.item.control_backups && data.item.name_db_backups_others && data.item.route_folder_backups_others">
                    <div v-if="data.item.backups">
                      <div v-if="showFieldSystem(data.item, 'backups') == 'Activo'" class="text-success font-weight-bold" :id="'server-backups-' + data.item.id">
                        <b-icon icon="circle-fill" variant="success"></b-icon>
                      </div>
                      <div v-if="showFieldSystem(data.item, 'backups') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-backups-' + data.item.id">
                        <b-icon icon="circle-fill" variant="danger"></b-icon>
                      </div> 
                    </div>
                  </div>

                  <b-popover :target="'server-backups-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="data.item.backups">

                    <template #title>Estado del Backups</template>                    
                    <b>Estado:</b> {{JSON.parse(data.item.backups).status}}<br>
                    <b>Últimos Backups:</b> {{JSON.parse(data.item.backups).last}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.backups).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                   
                </template>

                <template v-slot:cell(status_process)="data">
                  <div v-if="data.item.status_process">
                    <div class="text-info font-weight-bold">
                      <b-icon icon="circle-fill" variant="info" animation="fade"></b-icon> Procesando
                    </div>
                  </div>
                </template>

                <template v-slot:cell(access)="data">
                  <div v-if="data.item.domain && data.item.access">
                    <div v-if="showFieldSystem(data.item, 'access') == 'Activo'" class="text-success font-weight-bold" :id="'server-access-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldSystem(data.item, 'access') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-access-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>                  
                  </div>

                  <b-popover :target="'server-access-' + data.item.id" 
                              triggers="hover" 
                              placement="top"
                              @show="configViewerJson" 
                              v-if="data.item.domain && data.item.access">

                    <template #title>Accesos URL</template>                    
                    <b>Estado:</b> {{JSON.parse(data.item.access).status}}<br>
                    <div v-if="JSON.parse(data.item.access).domain">
                      <b>Dominio HTTPS:</b> {{JSON.parse(data.item.access).domain}}<br>
                    </div>
                    <div v-if="JSON.parse(data.item.access).domain_http">
                      <b>Dominio HTTP:</b> {{JSON.parse(data.item.access).domain_http}}<br>
                    </div>                    
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.access).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>   
                </template>

                <template v-slot:cell(dns)="data">
                  <div v-if="data.item.domain && data.item.access">
                    <b-icon v-if="JSON.parse(data.item.access).dns_type_aaaa" icon="info-circle-fill" class="mr-1" variant="info" title="Encontramos registros tipo AAAA en los DNS"></b-icon>
                    <b-icon v-if="JSON.parse(data.item.access).dns_get_ip && (JSON.parse(data.item.access).dns_get_ip != data.item.server.host)" icon="folder-symlink-fill" variant="info" title="La IP asociada al registro tipo A no coincide con la IP del servidor"></b-icon>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                    
                    <b-dropdown-item @click="actionSystem(data.item)">
                      <b-icon icon="terminal-fill"></b-icon> Comandos
                    </b-dropdown-item>         
                    
                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>            
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filter()" />
            </nav>             
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
          <b-tab title="General">
            <b-row>
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre"
                                :disabled="crud.form.id>0">
                  </b-form-input>
                </b-form-group>
              </b-col>           
              <b-col md="12">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customer_id"
                            :where="conditionCustomersActive" />           
              </b-col>
              <b-col md="12">
                <FindObject tag="VPS Instalación"  
                            render="search"
                            type="instances-server" 
                            @select-object="loadInstancesServer($event)" 
                            :valueID="crud.form.instances_server_id" 
                            :orWhere="conditionServerType" />           
              </b-col>       
              
              <b-col md="12">
                <hr>
              </b-col> 

              <b-col md="12" v-if="parameters.integrateServices">
                <FindObject tag="Servicio"              
                            render="search"
                            display="name"
                            type="servicesCustomers" 
                            @select-object="loadServicesCustomersDetails($event)" 
                            :valueID="crud.form.services_customers_details_id" 
                            :where="conditionServicesCustomersDetails" 
                            :key="crud.form.customer_id"/>           
              </b-col>                  
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>           
            </b-row>
          </b-tab>
          <b-tab title="Backups">
            
            <b-row>
              <b-col md="12">
                <b-form-checkbox v-model="crud.form.control_backups" 
                                  switch 
                                  size="sm" 
                                  class="pull-left mt-2 mb-2">
                  ¿Hacer backups diariamente?
                </b-form-checkbox> 
              </b-col> 
            </b-row>

            <b-row v-if="crud.form.control_backups">          
              <b-col md="12">
                <FindObject tag="VPS Backups"
                            render="search"
                            type="instances-server" 
                            @select-object="loadInstancesServerBackups($event)" 
                            :valueID="crud.form.instances_server_backups_id" 
                            :where="conditionServerBackupsType" />           
              </b-col>                                  
          
              <b-col md="12">
                <b-form-group label="Nombre Base de Datos">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name_db_backups_others"
                                required
                                placeholder="Ingresar un Nombre">
                  </b-form-input>
                </b-form-group>
              </b-col> 

              <b-col md="12">
                <b-form-group label="Ruta Instalación Proyecto">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.route_folder_backups_others"
                                required
                                placeholder="Ingresar una Ruta">
                  </b-form-input>
                </b-form-group>
              </b-col> 
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations_backups"
                    placeholder="Ingresar las observaciones sobre el backups..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>                               
            </b-row>            
          </b-tab>
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD COMMAND -->
      <b-modal v-model="modal.command.active"
              size="lg"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.command.title}}
        </div>

        <b-row v-if="selectedSystem">
          <b-col md="2">                          
            <b-row>
              <b-col md="12">                          
                <b-dropdown block split text="Sistema" class="mb-2 w-100">                  
                  <b-dropdown-item @click="getActionSystem('system-others-get-version','Obtener Versión')" v-if="selectedSystem.domain">Obtener Versión</b-dropdown-item>        

                  <b-dropdown-divider v-if="selectedSystem.domain"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionSystem('system-others-check-access','Verificar Acceso')" v-if="selectedSystem.domain">Verificar Acceso</b-dropdown-item>          
                  
                  <b-dropdown-divider v-if="selectedSystem.domain"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-others-domain','Apuntar Dominio')">Apuntar Dominio</b-dropdown-item>                                    

                  <b-dropdown-divider v-if="selectedSystem.domain"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-others-disable','Deshabilitar Sistema')" v-if="!selectedSystem.status_disable && selectedSystem.domain">Deshabilitar</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-others-enable','Habilitar Sistema')" v-if="selectedSystem.status_disable && selectedSystem.domain">Habilitar</b-dropdown-item>                  
                </b-dropdown>          
              </b-col>              

              <b-col md="12" v-if="showBackups">
                <hr class="instance-system-divide">
              </b-col>
              
              <b-col md="12" v-if="showBackups">  
                <b-dropdown block split text="Backups" class="mb-2 w-100">
                  <b-dropdown-item @click="getActionSystem('system-backups-get-now','Backups Hoy')" v-if="selectedSystem.backups && showConfigurateBackupsActive()">Backups Hoy</b-dropdown-item>
                  <b-dropdown-divider v-if="selectedSystem.backups && showConfigurateBackupsActive()"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionSystem('system-backups-list-db','Listar Bases de datos')" v-if="selectedSystem.backups && showConfigurateBackupsActive()">Listar Base de Datos</b-dropdown-item>
                  <b-dropdown-item @click="getActionSystem('system-backups-list-img','Listar Imágenes')" v-if="selectedSystem.backups && showConfigurateBackupsActive()">Listar Imágenes</b-dropdown-item>
                  <b-dropdown-divider v-if="selectedSystem.backups && showConfigurateBackupsActive()"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionSystem('system-others-backups-force-files','Forzar Backups: FILES')" v-if="selectedSystem.backups && showConfigurateBackupsActive()">Forzar: FILES</b-dropdown-item>
                  <b-dropdown-item @click="getActionSystem('system-others-backups-force-db','Forzar Backups: DB')" v-if="selectedSystem.backups && showConfigurateBackupsActive()">Forzar: DB</b-dropdown-item>
                  <b-dropdown-divider v-if="selectedSystem.backups && showConfigurateBackupsActive()"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionSystem('system-others-backups-configurate','Configurar Backups')" v-if="!showConfigurateBackupsActive()">Configurar</b-dropdown-item>
                  <b-dropdown-item @click="getActionSystem('system-others-backups-delete','Quitar Backups')" v-if="showConfigurateBackupsActive()">Quitar</b-dropdown-item>
                </b-dropdown>
              </b-col>              

            </b-row>
          </b-col>
          <b-col md="10">
            <b-row>
              <b-col md="8">
                <h4>{{this.modal.command.subtitle}}</h4>
              </b-col>
              <b-col md="4" class="ssh-console-footer">
                <span v-if="selectedSystem.status_process">
                  <span class="text-info font-weight-bold">
                    <b-icon icon="circle-fill" variant="info" animation="fade"></b-icon> Procesando
                  </span>
                </span>                  
                <b-button variant="danger" size="sm" class="pull-right" title="Refrescar" @click="refreshSystem()" v-if="!SSHLoader">
                  <b-icon icon="arrow-clockwise" />
                </b-button>
              </b-col>
            </b-row>            
            <div v-if="!SSHLoader" class="ssh-console-system" v-html="SSHConsole"></div>
            <div v-else class="text-left mt-5">
              <b-spinner label="Spinning" type="grow"></b-spinner>
            </div>
          </b-col>
        </b-row>

        <div slot="modal-footer" class="ssh-console-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeActionSystem()">Cerrar</b-button>                  
        </div>
      </b-modal>     

      <!-- CRUD DOMAIN SYSTEM -->
      <b-modal v-model="modal.formSystem.active"
              size="xl"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSystem.title}}
        </div>

        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group label="Domino">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formSystem.domain"
                                required
                                placeholder="Ingresa un dominio">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="modal.formSystem.showCheck">
                <b-form-checkbox v-model="crud.formSystem.exists" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Proyecto está instalado y Virtual Host configurado. 
                </b-form-checkbox>                 
              </b-col>
              <b-col md="12" class="mt-3">
                <b-alert variant="warning" show v-if="modal.formSystem.showCheck && crud.formSystem.exists">
                  Si el proyecto ya fue instalado y configurado el virtual host, corroborar que el nombre del vhost sea "dominio.conf"
                </b-alert>   
                <b-alert variant="info" show>
                  Puede demorar unas horas en quedar activo. Dependerá de la propagación de los DNS de su proveedor de dominios.
                </b-alert>                                
              </b-col>
            </b-row>
          </b-col>                                       
          <b-col md="6" v-if="this.selectedSystem">
            <b-card bg-variant="light" header="Implementación" text-variant="dark">
              <b-card-text>
                <p>
                  Para apuntar su dominio personalizado al sistema, siga los siguientes pasos:<br>
                  <i>Se creará una carpeta en /var/www/html/[nombre proyecto]/public</i>
                </p>            
                
                <hr>                

                <ul>
                  <li>Ingresar a la <b>zona DNS</b> de su dominio.</li>                  
                  <li>Buscar el registro <b>(Tipo A => su-dominio.com => [IP])</b></li>
                  <li>Editar la IP actual por la IP de nuestro servidor. <b>IP = {{this.selectedSystem.server.host}}</b> </li>
                  <li>El siguiente registro debe mantener esta estructura <b>(CNAME => www.su-dominio.com => su-dominio.com)</b></li>
                </ul>              
              </b-card-text>
            </b-card>
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSystem.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveDomain()">Apuntar</b-button>          
        </div>
      </b-modal> 

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import FindObject from '@/components/inc/find/findObject'  
  import Storage from '@/handler/storageLocal'

  export default {
    components: {
      FindObject,      
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.INSTANCIAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudInstancesSystemDocker',
          elements: {}
        },         
        table : {
          items: [],
          fields: [],          
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,                
        },
        parameters: {
          integrateServices: Helper.hasParametersAccess(Param.P59),          
        },         
        crud: {
          form: {
            id: 0,
            name: '',  
            customer_id: 0,
            customer: null,
            instances_server_id: 0,
            instances_server: null,   
            instances_server_backups_id: 0,
            instances_server_backups: null,    
            services_customers_details_id: 0,
            services_customers_details: null,                         
            observations: '',
            own: false,
            type: 'docker',
            name_db_backups_others: '',
            route_folder_backups_others: '',
            control_backups: false,
            observations_backups: '',
          },     
          formSystem: {
            id: 0,
            domain: '',
            exists: false,
          },                  
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          command: {
            active: false,
            title: '',
            subtitle: 'Terminal',
          },       
          formSystem: {
            active: false,
            title: '',
            showCheck: false,   
          },             
        },
        filters: {
          name: '',
          customer: 0,
          instances_server: 0,
          own: false,
          type: 'docker',
        },
        arr: {
          select: {
            customers: [],
            instances_servers: [],
          }
        },       
        selectedServer: null,           
        selectedSystem: null,
        SSHConsole: '',
        SSHLoader: false,    
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoadSelects()
      this.restoreFilterStorage() 
      
      this.loadFieldTable()

      this.filter()
      this.configViewerJson()
    },
    computed: {      
      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },  
      conditionServerType(){
        return [
          {field: 'type', condition: 'docker'},
          {field: 'show_docker', condition: true}
        ];
      },  
      conditionServerBackupsType(){
        return [{field: 'type', condition: 'backups'}];
      },      
      conditionServicesCustomersDetails() {
        return [
          {field: 'customer_id', condition: this.crud.form.customer_id},
          {field: 'canceled', condition: false},
          {field: 'type_instances', condition: 'other_system'},          
        ];
      },      

      // render
      showBackups() {      
        if(this.selectedSystem.control_backups && this.selectedSystem.name_db_backups_others && this.selectedSystem.route_folder_backups_others) {
          return true
        } else {
          return false
        }
      },
    },    
    methods: {      
      // config
      configViewerJson() {    
        setTimeout(()=>{
          var arrElement = document.getElementsByClassName("json-tree-indent");        
          if(arrElement) {
            for (var i = 0; i < arrElement.length; i++){
              if(arrElement[i].innerHTML != '&nbsp;') {
                arrElement[i].innerHTML = '&nbsp;';            
              }                        
            }                              
          }
        },100)                    
      },        
      loadFieldTable() {       
        this.table.fields.push({key: 'id', label: 'ID', sortable: true, class: 'align-middle'});
        
        if(this.parameters.integrateServices) {
          this.table.fields.push({key: 'services', label: '', class: 'align-middle'});                
        }        
        
        this.table.fields.push({key: 'name', label: 'Nombre', class: 'align-middle'});
        this.table.fields.push({key: 'customer_id', label: 'Cliente', class: 'align-middle'});
        this.table.fields.push({key: 'observations', label: '', class: 'align-middle'});
        this.table.fields.push({key: 'instances_server_id', label: 'VPS Instalación', class: 'align-middle'});
        this.table.fields.push({key: 'status_process', label: '', class: 'align-middle text-center'});
        this.table.fields.push({key: 'instances_server_backups_id', label: 'VPS Backups', class: 'align-middle'});
        this.table.fields.push({key: 'backups', label: 'Backups', class: 'align-middle text-center'});
        this.table.fields.push({key: 'access', label: 'Accesos', class: 'align-middle text-center'});
        this.table.fields.push({key: 'dns', label: 'DNS', class: 'align-middle text-center'});
        this.table.fields.push({key: 'f_action', label:'', class: 'align-middle text-center'});                
      },
            
      // crud
      filter() {
        this.saveFilterStorage()

        this.table.isBusy = true

        var result = serviceAPI.filtrarSistema(this.filters, this.table.currentPage)

        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }  

          this.table.isBusy = false          
        })
        .catch(error => {
          this.table.isBusy = false
          //this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = '' 
        this.crud.form.customer_id = 0
        this.crud.form.customer = null
        this.crud.form.instances_server_id = 0
        this.crud.form.instances_server = null   
        this.crud.form.instances_server_backups_id = 0
        this.crud.form.instances_server_backups = null     
        this.crud.form.services_customers_details_id = 0
        this.crud.form.services_customers_details = null              
        this.crud.form.observations = ''
        this.crud.form.name_db_backups_others = ''
        this.crud.form.route_folder_backups_others = ''
        this.crud.form.control_backups = false
        this.crud.form.observations_backups = ''
        this.crud.form.own = false
        this.crud.form.type = 'docker'

        this.modal.form.title = "Nuevo Sistema"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name     
        this.crud.form.customer_id = item.customer.id
        this.crud.form.customer = item.customer
        this.crud.form.instances_server_id = item.instances_server_id
        this.crud.form.instances_server = item.server
        this.crud.form.instances_server_backups_id = item.instances_server_backups_id
        this.crud.form.instances_server_backups = item.server_backups
        this.crud.form.services_customers_details_id = item.services_customers_details_id
        this.crud.form.services_customers_details = item.services_customers_details        
        this.crud.form.observations = item.observations
        this.crud.form.name_db_backups_others = item.name_db_backups_others
        this.crud.form.route_folder_backups_others = item.route_folder_backups_others
        this.crud.form.control_backups = item.control_backups
        this.crud.form.observations_backups = item.observations_backups
        this.crud.form.own = false
        this.crud.form.type = 'docker'
        
        this.modal.form.title = "Editar Sistema"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el sistema (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Sistema',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarSistema(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filter()
              this.$awn.success("Sistema eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarSistema(this.crud.form);
        } else {
          var result = serviceAPI.agregarSistema(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filter()
          this.$awn.success("Cambios guardado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      }, 

      // render
      showConfigurateBackupsActive() {
        var status = true

        if(!this.selectedSystem.backups) {
          status = false
        } else {
          if(JSON.parse(this.selectedSystem.backups).status=='Inactivo') {
            status = false
          }
        }
        
        return status
      },
           
      // SELECT
      loadCustomers(object) {        
        if(object){
          this.crud.form.customer = object
          this.crud.form.customer_id = object.id                 
        } else {
          this.crud.form.customer = null
          this.crud.form.customer_id = 0
        }
      },
      loadInstancesServer(object) {
        if(object){
          this.crud.form.instances_server = object
          this.crud.form.instances_server_id = object.id                 
        } else {
          this.crud.form.instances_server = null
          this.crud.form.instances_server_id = 0
        }        
      },
      loadInstancesServerBackups(object) {
        if(object){
          this.crud.form.instances_server_backups = object
          this.crud.form.instances_server_backups_id = object.id                 
        } else {
          this.crud.form.instances_server_backups = null
          this.crud.form.instances_server_backups_id = 0
        }        
      },      
      loadServicesCustomersDetails(object) {
        if(object){
          this.crud.form.services_customers_details = object
          this.crud.form.services_customers_details_id = object.id                 
        } else {
          this.crud.form.services_customers_details = null
          this.crud.form.services_customers_details_id = 0
        }  
      },
      
      // fields server table 
      showFieldSystem(item, type) {
        var data = null
        var obj = null
       
        if(type == 'backups') {          
          obj = JSON.parse(item.backups)

          if(obj && obj.status) {
            data = obj.status
          }
        }

        if(type == 'access') {          
          obj = JSON.parse(item.access)

          if(obj && obj.status) {
            data = obj.status
          }
        }   

        return data
      },

      // filter select
      filterLoadSelects() {
        this.filterLoadCustomers();  
        this.filterLoadServers();
      },
      filterLoadCustomers(){
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    
          
          let arrActive = []
          let arrInactive = []          
          data.forEach(element => {            
            if(element.active)
              arrActive.push({ code: element.id, label: element.name })            
            else 
              arrInactive.push({ code: element.id, label: '[INACTIVO] - ' + element.name })                                    
          });      
          this.arr.select.customers = arrActive.concat(arrInactive);            
        })   
      },
      filterLoadServers() {
        var result = serviceAPI.obtenerServidor()        
        result.then((response) => {
          var data = response.data    

          data.forEach(element => {    
            if(element.type=='docker' || element.show_docker) {          
              this.arr.select.instances_servers.push({code: element.id, label: element.name})
            }
          });                                
        })   
      },

      // action
      actionSystem(item) {
        this.selectedServer = item.server
        this.selectedSystem = item

        this.SSHConsole = ''

        this.modal.command.title = "Comandos Sistema: [" + item.name + "]"
        this.modal.command.subtitle = "Terminal"

        this.modal.command.active = true
      },
      closeActionSystem() {
        this.modal.command.active=false
        this.filter()
      },
      refreshSystem() {
        var result = serviceAPI.mostrarSistema(this.selectedSystem.id);
        
        result.then((response) => {
          var data = response.data

          this.selectedSystem = data

          this.$awn.success("Sistema refrescado");
        }) 
      },

      getActionSystem(reference, titulo) {
        // titulo de referencia
        this.modal.command.subtitle = titulo

        // si apunta un dominio para el sistema, lo maneja otro evento
        if(reference == 'system-others-domain') {
          this.domainSystem(reference)
          return false
        }

        // si disable el sistema, lo maneja otro evento
        if(reference == 'system-others-disable') {
          this.disableSystem(reference)
          return false
        }

        // si enable el sistema, lo maneja otro evento
        if(reference == 'system-others-enable') {
          this.enableSystem(reference)
          return false
        }

        // si configura el backups, lo maneja otro evento
        if(reference == 'system-others-backups-configurate') {
          this.backupsConfigurateSystem(reference)
          return false
        }

        // si borro el backups, lo maneja otro evento
        if(reference == 'system-others-backups-delete') {
          this.backupsDeleteSystem(reference)
          return false
        }        

        // comandos
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }           

        this.SSHLoader = true
        var result = serviceAPI.commandActionSistema({
          id: this.selectedServer.id, 
          command: reference,
          reference: this.selectedSystem.name,
          domain: this.selectedServer.subdomain,
          system_id: this.selectedSystem.id,
        });

        result.then((response) => {                    
          var data = response.data

          this.SSHConsole = data
          this.filter()
          this.SSHLoader = false
        })
        .catch(error => {          
          this.SSHLoader = false
          this.$awn.alert(Error.showError(error));
        })
      },
      disableSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }           
                
        this.$bvModal.msgBoxConfirm('¿Desea deshabilitar el sistema (' + item.name + ')?', {
          title: 'Deshabilitar sistema',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en la deshabilitación'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })    
      },
      enableSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }           
                
        this.$bvModal.msgBoxConfirm('¿Desea habilitar el sistema (' + item.name + ')?', {
          title: 'Habilitar sistema',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'success',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en la habilitación'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },     
      backupsConfigurateSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }           
                
        this.$bvModal.msgBoxConfirm('¿Desea configurar el backups para el sistema (' + item.name + ')?', {
          title: 'Configurar Backups',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en configurar el backups'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        }) 
      },
      backupsDeleteSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }           
                
        this.$bvModal.msgBoxConfirm('¿Desea quitar el backups para el sistema (' + item.name + ')?', {
          title: 'Quitar Backups',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en quitar el backups'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },
      domainSystem() {
        this.crud.formSystem.id = this.selectedSystem.id
        this.crud.formSystem.domain = this.selectedSystem.domain

        if(this.selectedSystem.domain)
          this.modal.formSystem.showCheck = false
        else
          this.modal.formSystem.showCheck = true

        this.modal.formSystem.title = "Apuntar Dominio a Sistema: [" + this.selectedSystem.name + "]"        
        this.modal.formSystem.active = true
      },    
      saveDomain() {
        this.modal.formSystem.active = false
        
        // comandos        
        var reference = 'system-others-domain'
        var newDomain = this.crud.formSystem

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }
                   
        this.SSHLoader = true
        var result = serviceAPI.commandActionSistema({
          id: this.selectedServer.id, 
          command: reference,
          reference: this.selectedSystem.name,
          domain: this.selectedServer.subdomain,
          system_id: this.selectedSystem.id,
          newDomain: newDomain,
        });

        result.then((response) => {                    
          var data = response.data

          this.SSHConsole = data
          this.filter()
          this.SSHLoader = false          
        })
        .catch(error => {          
          this.SSHLoader = false
          this.$awn.alert(Error.showError(error));
        })
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_view_instances_system_docker', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_view_instances_system_docker')) {
          this.filters = JSON.parse(Storage.getValue('filter_view_instances_system_docker'))
        }         
      },       
    }    
  }
</script>
<style>
  .ssh-console-system {
    background-color: black;
    background-image: radial-gradient(
      rgba(0, 150, 0, 0.75), black 120%
    );
    height: 175px;
    color: white;
    font: 13px Inconsolata, monospace;    
    text-shadow: 0 0 5px #C8C8C8;
    padding: 10px;
    white-space: pre;
    overflow: auto;
  }
  .ssh-console-system::after {
    content: "";
    /*position: absolute;*/
    top: 0;
    left: 0;
    width: 100vw;
    height: 175px;
    background: repeating-linear-gradient(
      0deg,
      rgba(black, 0.15),
      rgba(black, 0.15) 1px,
      transparent 1px,
      transparent 2px
    );
    padding: 10px;
  }  
  .ssh-console-system::selection {
    background: #0080FF;
    text-shadow: none;
  }  
  .ssh-console-footer {
    z-index: 9999;
  }
  .instance-system-divide {
    margin-top: 1px;
    margin-bottom: 10px;    
  }  
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>